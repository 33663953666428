import React from 'react'
import SEO from '../../components/seo'
import SubPageLayoutNoHero from '../../layouts/subPageLayoutNoHero'
import styled from 'styled-components'
import { Section } from '../../components/styled/section'
import SubPageImgHero from '../../components/subPageImgHero'
import { Layout } from '../../components/styled/layout'
import ChooseRegion from '../../components/small_web_comp/ChooseRegion'
const PageSection = styled(Section)`
  display: flex;
  align-items: center;
  margin: 15px auto;
`
const Text = styled.div`
  flex: 1;
  margin-top: -3rem;
  //adding new data
  h4 {
    border-bottom: 2px solid #dddddd;
  }
`
const Rep = () => {
  const title = 'Reparasjon av alle bilmerker'
  const description1 =
    'Hos BilXtra Verksted opererer vi utelukkende med bildeler av OE-kvalitet eller Matching Quality. Det vil si at alle våre deler er av minst like god kvalitet som originalmontert deler.  '
  const description2 =
    'Lar du oss fikse bilen din, kan du derfor være trygg på at du får topp kvalitet!  '
  const Img = require('../../images/Vi-tar-vare-på-bilen-din.png')
  return (
    <Layout>
      <SubPageLayoutNoHero title={title}>
        <SEO title={title} />
        <SubPageImgHero
          title={title}
          description1={description1}
          description2={description2}
          Img={Img}
        ></SubPageImgHero>
        <PageSection>
          <Text>
            <h4>Reparasjon av alle bilmerker</h4>
            <p>
              Alle våre verksteder utfører reparasjon, og har verktøy, utstyr og diagnoseapparater
              for å kunne lese av og slette feilkoder på de fleste bilmerker. Våre mekanikere går
              jevnlig på kurs for å holde seg oppdatert på de nyeste bilmodellene. Verkstedene
              utfører også motorservice med skift av olje på motor, giroljeskift på manuell
              girkasse, og oljeskift på automatkasse. Vi utfører også bytte av registerreim.
            </p>

            <ChooseRegion />
          </Text>
        </PageSection>
      </SubPageLayoutNoHero>
    </Layout>
  )
}

export default Rep
